"use client"

import * as React from "react";
import { useEffect, useState } from "react";
import { DownloadButtonState, useDownloadContext } from "../../lib/context/CommonContext";
import {
    getCountryFromLocale,
    getDownloadvariant,
    getOnlineStatus,
    getQueryStrings,
    isGetAppPage,
    isQueryParamExist,
    pagePath, setIsIOS,
    setPageURL
} from "../../lib/utils/constants/PagesConstants";
import {
    BADGE_DOWNLOAD_BUTTON_TYPE,
    BANNER_DOWNLOAD_BUTTON_TYPE,
    FIXED_DOWNLOAD_BUTTON_TYPE,
    GET_APP_AF2_URL,
    GET_APP_AF3_URL,
    GET_APP_AF_URL,
    GET_APP_FACEBOOK_URL,
    GET_APP_FB_URL,
    GET_APP_KWAI_URL,
    GET_APP_MANIA_PLAY_URL,
    GET_APP_META_URL,
    GET_APP_MIGR_URL,
    GET_APP_ONLINK_CODE,
    GET_APP_PLAY_URL,
    GET_APP_REF_URL,
    GET_APP_TIKTOK_URL,
    GET_APP_US_URL,
    HEADER_DOWNLOAD_BUTTON_TYPE,
    HOME_PAGE_URL,
    INITIAL,
    IOS,
    PLAYSTORE_FB_PAGE,
    UTM_FB,
    UTM_GAW,
    UTM_SHARE_CHAT,
    UTM_TRUECALLER
} from "../../lib/utils/constants/GlobalConstants";
import {
    GAMESMANIA_DOT_IN,
    getWebsiteEnvironment,
    WINZO_DOT_APP,
    WINZO_DOT_GAMES_URL,
    WINZO_SPORT_URL
} from "../../lib/utils/constants/EnvironmentConstants";
import {
    APPSFLYER_WINZO_DOT_APP,
    APPSFLYER_WINZO_DOT_GAMES,
    appsFlyerLinkDownloadClick,
    appsFlyerLinkWithFbcParam,
    appsFlyerLinkWithParam,
    appsFlyerURLGenerator,
    appsFlyerURLGeneratorGetApp,
    appsFlyerURLGeneratorGetAppKwai,
    appsFlyerURLGeneratorGetAppRef,
    appsFlyerURLGeneratorGetAppManiaPlay,
    appsFlyerURLGeneratorOrganicPage,
    APPSFLYER_WINZOGAMES
} from "../../lib/utils/constants/AppsFlyerConstants";
import { COUNTRY } from "../../lib/utils/constants/LocaleAndCountryConstants";
import { LogEvent } from "../../lib/types/Analytics";
import {
    ABTestingAnalytics,
    BannerDownloadClickEvent,
    FixedDownloadClickEvent, getABPageviewEvent,
    GetAppAFBannerDownloadClick,
    GetAppAFDownloadClick,
    GetAppBannerDownloadClick,
    GetAppDownloadClick,
    GetAppFacebookDownloadClick,
    GetAppFBDownloadClick,
    GetAppFBFixedDownloadClick,
    GetAppFBMigrDownloadClick,
    GetAppFixedShareChatDownload,
    GetAppKwaiBannerDownloadClick,
    GetAppKwaiDownloadClick,
    GetAppMigrDownload,
    GetAppMigrShareChatDownload,
    GetAppPlayDownload,
    GetAppPlayShareChatDownload,
    GetAppTikTokBannerDownloadClick,
    GetAppTikTokDownloadClick,
    GetAppUsDownload,
    getGameDetailsBadgeEvent,
    getGameDetailsBadgeEventIOS,
    HeaderDownloadClick,
    RefBannerDownloadClick,
    RefFixedDownloadClick
} from "../../lib/analytics/AnalyticsEvents";
import Analytics from "../../lib/analytics/Analytics";
import { IAppsFlyerExtraParams } from "../../lib/types/common";

type AppsFlyerProps = {
    pageName: string,
    // apkUrl: string,
    variant?: string,
    disableOrganicUrl?: boolean
    extraParams?: IAppsFlyerExtraParams
}

const AppsFlyer = (props: AppsFlyerProps) => {
    const { pageName, variant, extraParams = {} } = props
    const [appsFlyerURL, setAppsFlyerURL] = useState<string>('');
    const [trueCallerPixelImage, setTrueCallerPixelImage] = useState<string>(
        "https://pixel.truecaller.com/v0/ty?&type=web&subtype=engage&event=visit&bundleid=com.winzo.gold&campaign=2795517833_5575134876_0_0"
    );

    const {
        downloadButtonState,
        clickApkUrl,
        locale,
        isAutoDownloadFlow,
        clickedBtnType,
        setIsUtmGAW,
        setIsNetworkToastOpen,
        downloadUrl: apkUrl,
        appsflyerDownloadClickEventUrl,
        setAppsflyerDownloadClickEventUrl
    } = useDownloadContext();


    const isGetApp: boolean = isGetAppPage(pageName);
    const isGetAppAf: boolean = pageName === GET_APP_AF_URL || pageName === GET_APP_AF2_URL || pageName === GET_APP_AF3_URL;
    const isHomePage: boolean = pageName === HOME_PAGE_URL;
    const isGetAppKwai: boolean = pageName === GET_APP_KWAI_URL;
    const isGetAppRef: boolean = pageName === GET_APP_REF_URL;
    const isGetAppPlay: boolean = pageName === GET_APP_PLAY_URL;
    const isGetAppMigr: boolean = pageName === GET_APP_MIGR_URL;
    const isGetAppUs: boolean = pageName === GET_APP_US_URL;
    const isGetAppTiktok: boolean = pageName === GET_APP_TIKTOK_URL;
    const isGetAppFb: boolean = pageName === GET_APP_FB_URL || pageName === GET_APP_META_URL;
    const isGetAppFacebook: boolean = pageName === GET_APP_FACEBOOK_URL;
    const isGetAppManiaPlay: boolean = pageName === GET_APP_MANIA_PLAY_URL;

    //All analytics handling function
    const handleAnalytics = (): void => {
        // A/B Testing pageview
        if (variant) {
            Analytics.getInstance().logEvent(getABPageviewEvent(variant));
        }
    }

    useEffect(() => {
        if (downloadButtonState === DownloadButtonState.Downloading) {
            console.log("AppsFlyer", "fire Click", appsflyerDownloadClickEventUrl)
            setAppsFlyerURL(appsflyerDownloadClickEventUrl)

            if (!isAutoDownloadFlow) {
                sendAnalytics(clickedBtnType)
            }
        }
    }, [downloadButtonState, isAutoDownloadFlow]);

    useEffect(() => {
        clickApkUrl && appFlyerEventHandle(clickApkUrl)
    }, [clickApkUrl]);

    useEffect(() => {
        apkUrl && appFlyerEventHandle(apkUrl, extraParams)
    }, [apkUrl, locale]);

    useEffect(() => {
        if (pageName === PLAYSTORE_FB_PAGE) {
            let url: string = appsFlyerLinkWithFbcParam();
            setAppsFlyerURL(url);
        }
        if(pageName === GET_APP_FACEBOOK_URL || pageName === GET_APP_FB_URL){
            let url: string = appsFlyerURLGenerator();
            setAppsFlyerURL(url);
        }
        handleAnalytics();
        setIsIOS();
        setIsUtmGAW(getQueryStrings().utm === UTM_GAW);
        setPageURL(pageName);
    }, []);

    // fire appsFLyer on page change
    useEffect(() => {
        const architecture = getQueryStrings(apkUrl).arch;
        if (isGetAppFb) {
            let url: string = appsFlyerLinkWithFbcParam();
            setAppsFlyerURL(url);
        }else if (pageName === GET_APP_FACEBOOK_URL) {
            let url = appsFlyerLinkWithParam();
            url && setAppsFlyerURL(url);
        }
        else if (!isQueryParamExist() && apkUrl && architecture !== INITIAL && !isHomePage && !props.disableOrganicUrl) {
            appsFlyerURLGeneratorOrganicPage(locale, architecture)
            .then(resultURL => clickEventUrlMethod(resultURL));
        }
    }, [pageName, locale, apkUrl]);

    const appFlyerEventHandle = (downloadUrl: string, extraParams?:IAppsFlyerExtraParams) => {
        const architecture = getQueryStrings(downloadUrl).arch;
        if (architecture && architecture !== INITIAL) {
            let url: string | null = null
            if (isHomePage) {
                if (getWebsiteEnvironment().baseUrl === WINZO_DOT_GAMES_URL) {
                    clickEventUrlMethod(appsFlyerURLGeneratorGetApp(architecture, APPSFLYER_WINZO_DOT_GAMES.GET_APP_ONELINK));
                } else if (getWebsiteEnvironment().baseUrl === WINZO_DOT_APP) {
                    clickEventUrlMethod(appsFlyerURLGeneratorGetApp(architecture, APPSFLYER_WINZO_DOT_APP.GET_APP_ONELINK));
                } else if (getWebsiteEnvironment().baseUrl === WINZO_SPORT_URL) {
                    url = appsFlyerLinkWithParam();
                } else {
                    if (extraParams) {
                        extraParams.af_android_url = downloadUrl;
                    }
                    appsFlyerURLGeneratorOrganicPage(
                        locale,
                        architecture,
                        getDownloadvariant(),
                        extraParams
                    ).then(resultURL => clickEventUrlMethod(resultURL))
                }
                url && setAppsFlyerURL(url);
            } else if (isGetApp) {
                if (getWebsiteEnvironment().baseUrl === WINZO_DOT_GAMES_URL) {
                    clickEventUrlMethod(appsFlyerURLGeneratorGetApp(architecture, APPSFLYER_WINZO_DOT_GAMES.GET_APP_ONELINK));
                } else {
                    clickEventUrlMethod(appsFlyerURLGeneratorGetApp(architecture))
                }
            } else if (isGetAppKwai && getCountryFromLocale(locale) === COUNTRY.INDIA) {
                clickEventUrlMethod(appsFlyerURLGeneratorGetAppKwai(architecture))
            } else if (isGetAppRef) {
                if (getCountryFromLocale(locale) === COUNTRY.INDIA) {
                    clickEventUrlMethod(appsFlyerURLGeneratorGetAppRef(architecture, APPSFLYER_WINZOGAMES.DEFAULT.GET_APP_REF))
                } else {
                    clickEventUrlMethod(appsFlyerURLGeneratorGetAppRef(architecture, APPSFLYER_WINZOGAMES.GLOBAL.GET_APP_REF))
                }
            } else if (isGetAppManiaPlay && getWebsiteEnvironment().baseUrl === GAMESMANIA_DOT_IN) {
                clickEventUrlMethod(appsFlyerURLGeneratorGetAppManiaPlay(architecture))
            }
        }
    }

    const clickEventUrlMethod = (url) =>  {
        if (url.includes("apk")) 
            setAppsflyerDownloadClickEventUrl(url);
    }

    const sendAnalytics = (type: string, btnType?: string | null) => {
        if (isGetAppFb && getOnlineStatus() === false) {
            setIsNetworkToastOpen(true);
            return;
        }

        if (isGetAppAf) {
            const oneLinkUrl = appsFlyerLinkDownloadClick(
                GET_APP_ONLINK_CODE,
                pagePath()
            );
            setAppsFlyerURL(oneLinkUrl);
        }
        let event: LogEvent = {
            category: "",
            action: "",
            label: "",
            fbEvent: "",
            value: 0,
        };

        if (type === HEADER_DOWNLOAD_BUTTON_TYPE) {
            event = isGetAppFb ?
                GetAppFBDownloadClick : isGetAppFacebook
                    ? GetAppFacebookDownloadClick
                    : HeaderDownloadClick;
        } else if (type === BANNER_DOWNLOAD_BUTTON_TYPE) {
            event =
                isGetApp || isGetAppPlay
                    ? getQueryStrings().utm === UTM_SHARE_CHAT
                        ? GetAppPlayShareChatDownload
                        : getQueryStrings().utm === UTM_FB
                            ? GetAppFBDownloadClick
                            : isGetAppPlay
                                ? GetAppPlayDownload
                                : GetAppBannerDownloadClick
                    : isGetAppMigr
                        ? getQueryStrings().utm === UTM_SHARE_CHAT
                            ? GetAppMigrShareChatDownload
                            : getQueryStrings().utm === UTM_FB
                                ? GetAppFBMigrDownloadClick
                                : GetAppMigrDownload
                        : isGetAppUs
                            ? GetAppUsDownload
                            : isGetAppAf
                                ? GetAppAFBannerDownloadClick
                                : isGetAppRef
                                    ? RefBannerDownloadClick
                                    : isGetAppKwai
                                        ? GetAppKwaiBannerDownloadClick
                                        : isGetAppTiktok
                                            ? GetAppTikTokBannerDownloadClick
                                            : isGetAppFb ?
                                                GetAppFBDownloadClick
                                                : isGetAppFacebook ?
                                                    GetAppFacebookDownloadClick
                                                    : BannerDownloadClickEvent;
        } else if (type === FIXED_DOWNLOAD_BUTTON_TYPE) {
            event = isGetApp
                ? getQueryStrings().utm === UTM_SHARE_CHAT
                    ? GetAppFixedShareChatDownload
                    : getQueryStrings().utm === UTM_FB
                        ? GetAppFBFixedDownloadClick
                        : GetAppDownloadClick
                : isGetAppAf
                    ? GetAppAFDownloadClick
                    : isGetAppFb
                        ? GetAppFBDownloadClick
                        : isGetAppRef
                            ? RefFixedDownloadClick
                            : isGetAppKwai
                                ? GetAppKwaiDownloadClick
                                : isGetAppTiktok
                                    ? GetAppTikTokDownloadClick
                                    : isGetAppFacebook
                                        ? GetAppFacebookDownloadClick
                                        : FixedDownloadClickEvent;
        } else if (type === BADGE_DOWNLOAD_BUTTON_TYPE) {
            if (btnType === IOS)
                event = getGameDetailsBadgeEventIOS(pageName);
            else event = getGameDetailsBadgeEvent(pageName);
        }

        if (isGetApp && getQueryStrings().utm === UTM_TRUECALLER) {
            setTrueCallerPixelImage("https://pixel.truecaller.com/v0/ty?&type=web&subtype=engage&event=download&bundleid=com.winzo.gold&campaign=2795517833_5575134876_0_0");
        }

        Analytics.getInstance().logEvent(event);
        if (variant) {
            event = ABTestingAnalytics(event, variant)
            Analytics.getInstance().logEvent(event);
        }
    }

    return (
        <>
            {isGetApp && getQueryStrings().utm === UTM_TRUECALLER && (
                <img
                    height={1}
                    width={1}
                    style={{ display: "none", position: "absolute" }}
                    src={trueCallerPixelImage}
                    alt="true caller"
                />
            )}
            <img
                height={1}
                width={1}
                style={{ display: "none", position: "absolute" }}
                src={appsFlyerURL}
                alt="online social gaming app"
            />
        </>
    )
}

export default AppsFlyer